<template>
  <div class="feinongPC">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="banner">
      <span @click="openLogin" class="btn1" alt="" />
    </div>
    <div class="part-1">
      <img src="/subject/feinong/tit1s.png" class="tit" alt="" />
      <p class="p1">美元现金1手即领，所有用户均可领取</p>
      <!-- <span class="span1" @click="openPop" style=" text-decoration: underline;color: blue;"
        >活动详情</span
      > -->
      <img  @click="openPop" class="span1" src="/subject/feinong/detail_btn.png" alt="" width="200">
      <div class="part1-centent">
        <a href="javascript:;" @click="openLogin" class="red1"></a>
        <a href="javascript:;" @click="openLogin" class="red2"></a>
        <a href="javascript:;" @click="openLogin" class="red3"></a>
        <a href="javascript:;" @click="openLogin" class="red4"></a>
        <a href="javascript:;" @click="openLogin" class="red5"></a>
      </div>
      <p class="p1" style="color: #333;">
        <!-- 非农活动倒计时:
        <span>{{ day }}天{{ hour }}时{{ min }}分{{ second }}秒</span> -->
        前往<span style="color: red;">APP-我的-活动中心-非农周交易返赠</span>查看活动进度
      </p>
      <div class="btnBox">
        <img @click="openLive" src="/subject/feinong/btnx.png" class="btns" alt="" />
        <img @click="openLogin" src="/subject/feinong/btnc.png" class="btns" alt="" />
      </div>
      <p class="p1">机会有限，先到先得</p>
    </div>
    <div class="part-2">
      <img src="/subject/feinong/tit2s.png" class="tit" alt="" />
      <div class="part2-centent">
        <ul>
          <li>
            非农就业数据发布时间：每月第一个星期五，北京时间晚20:30（11月-3月冬令时21:30）；
          </li>
          <li>
            非农就业数据，反映的是美国非农业人口就业状况的数据，客观的反应了美国经济走势，影响美元价格波动；
          </li>
          <li>
            因美元价格与伦敦金价格的相关性，每次非农数据发布前后，都会引发伦敦金/银市场行情大幅波动，是伦敦金/银买卖的大好时机；
          </li>
        </ul>
        <p>- 非农数据数值增加<i></i>美国经济向好<i></i>利好美元<i></i>利空金银；</p>
        <p>- 非农数据数值减少<i></i>美国经济走低<i></i>利空美元<i></i>利好金银；</p>
        <div class="changeB">
          <div class="spBox" @mouseleave="tabLeave()">
            <span @mouseover="hoverLi(0)" :class="hoverControl == 0 ? 'active' : ''"
              >美国失业率报告</span
            >
            <span @mouseover="hoverLi(1)" :class="hoverControl == 1 ? 'active' : ''"
              >美国非农就业<Br />人数报告</span
            >
          </div>
          <div class="echartsBox" @mouseleave="tabLeave()">
            <div
              @mouseover="hoverLi(0)"
              id="echartsBox_one"
              v-show="hoverControl == 0"
              class="echartsBoxItem"
            ></div>
            <div
              @mouseover="hoverLi(1)"
              id="echartsBox_two"
              v-show="hoverControl == 1"
              class="echartsBoxItem"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="part-3">
      <img class="tit" src="/subject/feinong/tit3s.png" alt="" />
      <div class="part3-centent">
        <h3><i></i>经济数据(公布时间11/1 20:30)</h3>
        <table>
          <tr>
            <th></th>
            <th>前值</th>
            <th>预测值</th>
          </tr>
          <tr>
            <th>美国10月失业率</th>
            <th>4.1%</th>
            <th>4.1%</th>
          </tr>
          <tr>
            <th>美国10月季调后非农就业人口</th>
            <th>25.4万</th>
            <th>18万</th>
          </tr>
        </table>

        <h3><i></i>黄金操作建议</h3>
        <img src="/subject/feinong/part3M2s.png" class="part3M2" alt="" />
        <p>
          <b>压力</b>：2750美元/盎司<br />
          <b>支撑</b>：2700美元/盎司<br />
          <b>交易研判</b>：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10月4日周五，美国劳工统计局公布报告显示，美国9月就业增长大幅超出预期，创下今年3月以来的最大增幅，失业率意外下降，工资同比涨幅上升，缓解了人们对美国劳动力市场恶化的担忧。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;美国劳工统计局数据显示，美国8月非农就业人数增加14.2万人，低于市场预期的16.5万人，7月数据从11.4万人大幅下修至8.9万人，6月数据下修了6.1万，经过修订，6月和7月就业人数总计比之前报告的少了8.6万个。失业率从7月的4.3%下降至4.2%，持平预期，失业率为今年3月以来首次出现下降。目前制造业就业是最大拖累，服务业和政府部门就业回暖。非农增长趋势明显放缓，且家庭调查和企业调查数据背离，新移民涌入导致数据失真。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9月私营部门就业人口增加22.3万人，预期增加12.5万人，8月前值为增加11.8万人。9月制造业就业人口减少0.7万人，预期为减少0.8万人，8月前值为减少2.4万人。衡量私营就业变化广度的就业扩散指数升至今年年初以来的最高水平。 <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;美国9月失业率4.1%，低于预期的4.2%，8月前值为4.2%。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;从日线级别走势图来看，黄金持续受益于未来美联储降息展望、全球央行持续购买、地缘政治冲突依然频发…等多项利好支撑，预料还是会持续偏向不断创下历史新高的走势。可以在拉回2700美元/盎司整数大关，或着是再次突破历史新高与2750/2800这种整数大关目标后，持续做多！
        </p>
        <p class="sp">
          【以上内容仅代表个人观点，不代表平台立场，仅供参考，并不构成任何操作建议。坚定自己的思路，做好相应风险控制。】
        </p>
      </div>
      <img @click="openLogin" src="/subject/feinong/btnf.png" class="btn1" alt="" />
    </div>
    <div class="part-4">
      <img class="tit" src="/subject/feinong/tit4s.png" alt="" />
      <div class="part4-centent">
        <img class="phone" src="/subject/feinong/phone.png" alt="" />
        <div class="partRight">
          <ul>
            <li>多维技术指标</li>
            <li>实时热点更新</li>
          </ul>
          <ul>
            <li>专业建仓建议</li>
            <li>解析非农数据</li>
          </ul>
          <p>下载红狮app，随时掌握非农动态 !</p>
          <div class="appCode">
            <div class="codeBody">
                <div>MT4账户专用版本</div>
                <div class="codeBox">
                    <div class="codeItem">
                        <Qrcode :link="iosMt4DownloadUrl" :size="168" :iconSize="30"/>
                        <div>iOS</div>
                    </div>
                    <div class="codeItem">
                        <Qrcode :link="androidMt4DownloadUrl" :size="168"/>
                        <div>Android</div>
                    </div>
                </div>
            </div>
            <div class="codeBody">
                <div>MT5账户专用版本</div>
                <div class="codeBox">
                    <div class="codeItem">
                        <Qrcode :link="iosMt5DownloadUrl" :size="168"/>
                        <div>iOS</div>
                    </div>
                    <div class="codeItem">
                        <Qrcode :link="androidDownloadUrl" :size="168"/>
                        <div>Android</div>
                    </div>
                </div>
            </div>
          </div>
          <div class="serveBox">
              <div class="serveBox-item">
                  <p>1、MT4版本用MT4账户，MT5版本用MT5账户，两者不互通；</p>
              </div>
              <div class="serveBox-item">
                  <p>2、如无法确定所用账户的MT版本，请联系<span class="serveBox-serve" @click="openLive">在线客服</span>查询。</p>
              </div>
          </div>
        </div>
        <div class="partRight"></div>
      </div>
      <p class="p2">
        红狮专注伦敦金/银15年，只为给您这1秒的交易，带来更专业的体验
      </p>
    </div>
    <contactusVue></contactusVue>
    <div class="popup" v-if="controlPop">
      <span @click="closePop" class="guanbi1">X</span>
      <ul class="popContent">
        <li>
          <span>活动对象：</span>
          本公司所有新老用户
        </li>
        <li>
          <span>活动交易时间：</span>
          <!-- {{ activityTime.FNStartTime }}—{{ activityTime.FNEndTime }} -->
          2024年10月28日06:00 ~ 2024年11月02日06:00
        </li>
        <li>
          <span>兑奖截止时间：</span>
          <!-- {{ activityTime.FNExchangeEndTime }}（逾期失效） -->
          2024年11月25日00:00（逾期失效）
        </li>
        <li>
          <span>活动规则：</span><br />
          活动期间内，凡在本公司的APP或MT交易平台交易伦敦金、伦敦银等指定品种，完成指定交易手数，即可兑换相应美元现金奖励，交易时间结束后，统一开始兑换，限兑奖一次；
          <br />
        </li>
        <li>
          <span>交易奖励：</span><br />
          <table>
            <tr>
              <th>赠送条件</th>
              <th>美元现金奖励</th>
            </tr>
            <tr>
              <th>≥1手</th>
              <th>1美元</th>
            </tr>
            <tr>
              <th>≥5手</th>
              <th>10美元</th>
            </tr>
            <tr>
              <th>≥10手</th>
              <th>25美元</th>
            </tr>
            <tr>
              <th>≥50手</th>
              <th>150美元</th>
            </tr>
            <tr>
              <th>≥100手</th>
              <th>350美元</th>
            </tr>
            <tr>
              <th>≥500手</th>
              <th>1750美元</th>
            </tr>
          </table>
        </li>
        <li>
          <span>领取方式：</span><br />
          1.非农奖励在“APP”→“我的”→“活动中心”→“非农活动”中领取；<br />
          2.活动只计算非农周交易手数，奖励会在非农交易周之后开启兑换；<br />
          3.奖励后的美元可直接提现，亦可继续用于交易；<br />
          4.美元现金奖励需在兑奖截止日期前领取，逾期失效； <br />
        </li>
        <li>
          <span>注意事项</span><br />
          1.客户参与活动需在本公司开立真实交易账户，为保证资金安全，参与该活动的新用户，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br />
          2.本活动指定品种详情可咨询客服，交易手数计算以开平仓为准；<br />
          3.各账户层级用户均可参加本活动，美元现金奖励将依据活动交易期间的最终开平仓手数发放；<br />
          4.本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。<br />
        </li>
      </ul>
    </div>
    <div class="window-mask" v-if="controlPop"></div>
  </div>
</template>

<script>
import ContactusVue from '../../Contactus.vue';
import { getFeinongTime } from '../../../../api/info';
import { getSYLApi, getFNApi } from '../../../../api/subject/feinong';
import { mapState  } from "vuex";
import Qrcode from '../../QrCode/index.vue'
export default {
  name: 'feinongPCs',
  components: {
    ContactusVue,
    Qrcode,
  },
  data() {
    return {
      timer: Function,
      controlPop: false,
      hoverControl: 0,
      timeEnd: '2tian',
      curStartTime: '2024-07-06 06:00:00',
      day: '00',
      hour: '00',
      min: '00',
      second: '00',
      screenWidth: null,
      navBarFixed: false,
      scroll: '',
      activityTime: {
        FNStartTime: null,
        FNEndTime: null,
        FNExchangeEndTime: null,
      },
      fnX: [],
    };
  },
  computed: {
    ...mapState('common', {
      androidMt4DownloadUrl: 'androidMt4DownloadUrl',
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt4DownloadUrl: 'iosMt4DownloadUrl',
      iosMt5DownloadUrl: 'iosMt5DownloadUrl',
    })
  },
  methods: {
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem('craig');
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    openPop() {
      this.controlPop = true;
    },
    closePop() {
      this.controlPop = false;
    },
    openUser() {
      window.open('https://www.rlcproltd.net/uc/signUp/real');
    },
    openLogin() {
      window.open('https://www.rlcproltd.net/uc/login');
    },
    openLive() {
      window.open(
        this.$parent.live800UrlUc
        // "https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377"
      );
    },
    hoverLi(attr) {
      clearInterval(this.timer);
      this.hoverControl = attr;
    },
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(this.curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        let day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 三元表达式判断不满10前面加0
        this.day = day < 10 ? '0' + day : day;
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? '0' + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? '0' + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        this.second = s < 10 ? '0' + s : s;
      } else {
        this.day = '00';
        this.hour = '00';
        this.min = '00';
        this.second = '00';
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000);
      }
    },
    // tabLeave鼠标离开事件
    tabLeave() {
      this.timer = setInterval(this.updateDiv, 3000);
    },
    // 切换rollBox的方法
    updateDiv() {
      if (this.hoverControl == 0) {
        this.hoverControl = this.hoverControl + 1;
      } else if (this.hoverControl == 1) {
        this.hoverControl = 0;
      }
    },
    formatterHover(params) {
      return (
        '<span style="padding-left:5px;height:30px;line-height:30px;display: inline-block;">' +
        params[0].axisValue +
        '<br>今值: ' +
        params[0].data +
        '</span>'
      );
    },
  },
  created() {
    this.$emit('controlShow', true);
    // if(document.body.clientWidth<500){
    //   this.$router.push({
    //     name:'autumnOctWap',
    //   })
    // }
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem('craig')) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem('craig', window.location.origin);
      } else {
        sessionStorage.setItem('craig', this.$route.query.craig);
      }
    }
    // 每4秒自动切换rollBox
    this.timer = setInterval(this.updateDiv, 3000);
    // 获取 活动 时间
    getFeinongTime().then((res) => {
      this.activityTime = res.data;
    });
    // 美国失业率报告
    getSYLApi().then((res) => {
      var arr_X = [];
      var arr_Y = [];
      var arr = [res.data[0], res.data[1], res.data[2], res.data[3], res.data[4], res.data[5]];
      for (let i = arr.length - 1; i >= 0; i--) {
        arr_X.push(arr[i].Date.slice(0, 10));
        arr_Y.push(arr[i].Announcement);
      }
      let myChart = this.$echarts.init(document.getElementById('echartsBox_one'));
      let option = {
        legend: {
          textStyle: { fontSize: 16 },
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove',
          enterable: true, //鼠标是否可进入提示框浮层中
          formatter: this.formatterHover, //修改鼠标悬停显示的内容
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: arr_X,
          // axisLabel: {
          //   interval: 1
          // },
        },
        yAxis: {
          type: 'value',
          name: '今值(%)',
        },
        series: [
          {
            data: arr_Y,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: '#BFD6FB',
            },
            areaStyle: {
              normal: {
                color: '#e9f1fe',
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    });
    // 美国非农就业
    getFNApi().then((res) => {
      var arr_X = [];
      var arr_Y = [];
      var arr = [res.data[0], res.data[1], res.data[2], res.data[3], res.data[4], res.data[5]];
      for (let i = arr.length - 1; i >= 0; i--) {
        arr_X.push(arr[i].Date.slice(0, 10));
        arr_Y.push(arr[i].Announcement);
      }
      let myChart_two = this.$echarts.init(document.getElementById('echartsBox_two'));
      let option_two = {
        legend: {
          textStyle: { fontSize: 16 },
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove',
          enterable: true, //鼠标是否可进入提示框浮层中
          formatter: this.formatterHover, //修改鼠标悬停显示的内容
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: arr_X,
          // axisLabel: {
          //   rotate: 30,
          //   interval: 0,
          // },
        },
        yAxis: {
          type: 'value',
          name: '今值(万人)',
        },
        series: [
          {
            data: arr_Y,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: '#BFD6FB',
            },
            areaStyle: {
              normal: {
                color: '#e9f1fe',
              },
            },
          },
        ],
      };
      myChart_two.setOption(option_two);
    });
  },
  mounted() {
    this.countTime();
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.addEventListener('scroll', this.handleScroll);
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function(n) {
      if (n <= 500) {
        this.$router.push({
          name: 'feinongWaps',
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
img {
  display: block;
}

.tabbar {
  background-color: #ffffff;
  padding: 10px 30px;

  img {
    width: 130px;
  }
}

.navBarFixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.feinongPC {
  width: 100%;
  background: url('/subject/feinong/bg.png') no-repeat center;
  background-size: 100% 100%;

  .banner {
    width: 100%;
    background: url('/subject/feinong/banners.jpg') no-repeat center;
    background-size: cover;
    height: 682px;
    position: relative;

    .btn1 {
      position: absolute;
      width: 314px;
      height: 84px;
      left: 50%;
      top: 538px;
      cursor: pointer;
      margin-left: -160px;
    }
  }

  .part-1 {
    width: 100%;
    padding-top: 60px;

    .tit {
      display: block;
      margin: 0 auto;
    }

    .p1 {
      color: #e26f08;
      font-size: 30px;
      text-align: center;
      margin: 20px 0;
    }

    .span1 {
      display: block;
      margin: 0 auto;
      text-align: center;
      color: #e26f08;
      font-size: 30px;
      cursor: pointer;
    }

    .part1-centent {
      width: 1334px;
      height: 715px;
      background: url('/subject/feinong/bgred.png') no-repeat;
      background-size: cover;
      display: block;
      margin: 0 auto;
      position: relative;

      .red1 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 236px;
        left: 374px;
        cursor: pointer;
      }

      .red2 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 236px;
        right: 369px;
        cursor: pointer;
      }

      .red3 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 491px;
        left: 182px;
        cursor: pointer;
      }

      .red4 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 491px;
        left: 614px;
        cursor: pointer;
      }

      .red5 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 491px;
        right: 177px;
        cursor: pointer;
      }
    }

    .btnBox {
      width: 1200px;
      display: block;
      margin: 0 auto;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;

      img {
        display: inline-block;
        cursor: pointer;
      }

      img:nth-child(1) {
        margin-right: 98px;
      }
    }
  }

  .part-2 {
    width: 100%;
    padding-top: 50px;
    background: url('/subject/feinong/part-2.jpg') no-repeat;
    background-size: 100% 100%;
    .tit {
      display: block;
      margin: 0 auto;
    }

    .p1 {
      color: #000;
      font-size: 30px;
      text-align: center;
      margin: 20px 0;
    }

    .part2tit {
      width: 100%;
    }

    .part2-centent {
      width: 1333px;
      height: 883px;

      box-sizing: border-box;
      margin: 0 auto;
      margin-top: 30px;
      padding: 50px 40px;

      p {
        color: #000;
        font-size: 28px;
        margin-bottom: 25px;

        i {
          width: 25px;
          height: 22px;
          display: inline-block;
          background: url('/subject/feinong/icon_l.png') no-repeat;
          background-size: cover;
          margin: 0 10px;
        }
      }

      ul {
        justify-content: center;
        align-items: center;

        li {
          width: 1200px;
          color: #000;
          font-size: 28px;
          list-style-type: disc;
          margin-bottom: 40px;
        }
      }

      .changeB {
        width: 1253px;
        position: relative;
        display: flex;

        .spBox {
          width: 240px;
          display: inline-block;
        }

        .spBox span {
          background: #fff;
          color: #efaa05;
          font-size: 28px;
          display: block;
          text-align: center;
          height: 164px;
          box-sizing: border-box;
          padding-top: 43px;
        }

        .spBox span:nth-child(1) {
          border-top-left-radius: 5px;
        }

        .spBox span:nth-child(2) {
          border-bottom-left-radius: 5px;
        }

        .spBox span.active {
          background: linear-gradient(to top right, #e4760b, #fdab5f);
          color: #fff;
        }

        .echartsBox {
          width: 1013px;
          height: 328px;
          background-color: #fff;

          .echartsBoxItem {
            width: 1013px;
            height: 328px;
          }
        }

        img {
          position: absolute;
          right: -22px;
          top: 0;
        }
      }
    }
  }

  .part-3 {
    padding-top: 60px;
    width: 100%;
    padding-bottom: 60px;
    .tit {
      display: block;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .part3-centent {
      display: block;
      margin: 0 auto;
      width: 1333px;
      // height: 1505px;

      padding-top: 30px;
      box-sizing: border-box;
      padding-left: 80px;

      h3 {
        font-size: 40px;
        color: #efaa05;

        i {
          display: inline-block;
          width: 5px;
          height: 24px;
          margin-right: 12px;
          background: #efaa05;
          border-radius: 5px;
        }
      }

      table {
        width: 92%;
        border: 2px solid #e28172;
        border-radius: 16px;
        background-color: #fffbee;

        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 80px;
            border-bottom: 1px solid #c4693b;
            border-right: 1px solid #c4693b;
            padding: 5px;
            color: #c4693b;
            font-size: 24px;
          }

          th:last-child {
            border-right: 1px solid transparent;
          }
        }

        tr:nth-child(1) {
          th {
            width: 35%;
            font-size: 28px;
            font-weight: 600;
            color: #cd7f4e;
          }
        }

        tr:last-child {
          th {
            border-bottom: none;
          }
        }
      }

      .part3M2 {
        width: 93%;
        margin-bottom: 10px;
        border-radius: 16px;
      }
      b {
        color: #efaa05;
      }
      p {
        font-size: 24px;
        color: #666666;
        line-height: 48px;
        width: 1200px;
      }

      .sp {
        font-size: 14px;
        opacity: 0.5;
        line-height: 30px;
      }
    }

    .btn1 {
      display: block;
      margin: 0 auto;
      margin-top: 52px;
      cursor: pointer;
    }
  }

  .part-4 {
    padding-bottom: 100px;
    background: url('/subject/feinong/part4.jpg') no-repeat;
    background-size: cover;
    .tit {
      display: block;
      margin: 0 auto;
      margin-bottom: 60px;
    }

    padding-top: 60px;

    .part4-centent {
      width: 1300px;
      margin: 0 auto;
      margin-bottom: 100px;

      .partLeft {
        display: inline-block;
        vertical-align: top;
        padding-top: 160px;
      }

      .phone {
        display: inline-block;
        vertical-align: top;
        margin-right: 30px;
      }

      .partRight {
        display: inline-block;
        margin-top: 30px;

        p {
          color: #e4760b;
          font-size: 24px;
          line-height: 55px;
          margin-bottom: 50px;
          text-align: center;
        }

        .ewm {
          width: 258px;
          height: 258px;
          margin: 0 auto;
        }
      }

      ul {
        li {
          width: 356px;
          height: 74px;
          text-align: center;
          line-height: 74px;
          color: #e4760b;
          font-size: 26px;
          margin-bottom: 35px;
          margin-left: 40px;
          font-weight: bold;
          border: 1px solid #e4760b;
          border-radius: 10px;
          display: inline-block;
        }
      }
      .appCode {
        margin-left: 40px;
        display: flex;
        justify-content: center;
        text-align: center;
        .codeBody {
          >div:first-child {
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 20px;
          }
          .codeBox {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            margin-right: 30px;
            .codeItem {
              &:first-child {
                margin-right: 15px;
              }
              .canvasCode {
                border-radius: 6px;
              }
              div {
                line-height: 48px;
                background: #E4760B;
                color: #fff;
                border-radius: 4px;
                margin-top: 6px;
                font-size: 20px;
              }
            }
          }
        }
      }
      .serveBox {
        margin-left: 40px;
        &-serve {
          color: red;
          border-bottom: 1px solid red;
          cursor: pointer;
        }
        &-item {
          p {
            text-align: left;
            margin: 0;
            line-height: normal;
            font-size: 16px;
            color: #2D2D2D;
            text-indent: 1.5em; /* 设置整体缩进 */
            &:first-child {
              text-indent: 0;
            }
          }
        }
      }
    }

    .p2 {
      font-size: 28px;
      text-align: center;
      color: #e4760b;
    }

    .btnDown {
      display: block;
      margin: 0 auto;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}

.window-mask {
  width: 100%;
  height: 100%;
  background: #000;

  opacity: 0.75;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
}

.popup {
  overflow-x: hidden;
  width: 1100px;
  height: 900px;
  border-radius: 20px;
  background: #fdf5e6;
  position: fixed;
  top: 57%;
  left: 50%;
  transform: translate(-50%; -50%);
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
  overflow-y: scroll;
  padding-bottom: 40px;
  z-index: 99999999999999;

  .guanbi1 {
    float: right;
    cursor: pointer;
    margin-top: 8px;
    margin-right: 10px;
  }

  .popContent {
    box-sizing: border-box;
    padding: 40px 50px 20px;

    li {
      font-size: 18px;
      color: #a86e4f;
      line-height: 36px;

      span {
        font-weight: 600;
        color: #e34a3f;
      }

      img {
        width: 100%;
        height: auto;
        margin: 20px 0;
      }

      table {
        border: 1px solid #e34a3f;
        border-radius: 15px;
        width: 100%;

        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 50px;
            border-bottom: 1px solid #d2a765;
            border-right: 1px solid #d2a765;
            padding: 5px;
          }

          th:last-child {
            border-right: 1px solid transparent;
          }
        }

        tr:nth-child(1) {
          th {
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
