import request from '../../utils/axios';

let baseUrl=''
if (location.origin.indexOf('localhost') > -1 || location.origin.indexOf('192.168.0') > -1) {
    baseUrl = 'https://www.rlcproltd.net/api/proxy'
    // baseUrl = '/testapi' + '/api/proxy'
}  else {
    baseUrl = window.location.origin + '/api/proxy'
}
// 美国失业率报告
export function getSYLApi(data) {
    return request({
        // url: baseUrl + '/v4/USUP001',
        url: baseUrl+ '/common/pmCmsSystemRelay?path='+ '/v1/USUP006',
        method: 'POST',
        data
    })
}
// 美国非农就业
export function getFNApi(data) {
    return request({
        // url: baseUrl + '/v4/NF001',
        url: baseUrl + '/common/pmCmsSystemRelay?path='+ '/v1/NF006',
        method: 'POST',
        data
    })
}

